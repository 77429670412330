import React from 'react'
import { useEffect, useState } from 'react';
import animateScrollTo from "animated-scroll-to";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { SplideSlide } from '@splidejs/react-splide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import SiteNavigation from '../components/SiteNavigation';
import Video from '../components/Video';
import Slider from '../components/Slider';
import Contacts from '../components/Contacts';
import Footer from '../components/Footer';
import video from '../assets/video/new_video.mp4';


// Подарки
import imgPresent1 from '../assets/img/den_rozhdeniya/tort-podarok-hinkalnaya.jpg'
import imgPresent2 from '../assets/img/den_rozhdeniya/vino-podarok.jpg'
import imgPresent3 from '../assets/img/den_rozhdeniya/stol-gruzinske-jedy.jpg'
import imgPresent4 from '../assets/img/den_rozhdeniya/tanci-gruzie.jpg'
import imgPresent5 from '../assets/img/den_rozhdeniya/den-rozhdenije-v-restorane.jpg'

// Большой зал - 1 этаж
import imgBZal1 from '../assets/img/den_rozhdeniya/bolshoy-zal.jpg'
import imgBZal2 from '../assets/img/den_rozhdeniya/bolshoy-zal-2.jpg'
import imgBZal3 from '../assets/img/den_rozhdeniya/bolshoy-zal-3.jpg'
import imgBZal4 from '../assets/img/den_rozhdeniya/bolshoy-zal-4.jpg'

// Большой зал - 2 этаж
import imgBZalSecond1 from '../assets/img/den_rozhdeniya/bolshoy-zal-2etage-1.jpg'
import imgBZalSecond2 from '../assets/img/den_rozhdeniya/bolshoy-zal-2etage-2.jpg'
import imgBZalSecond3 from '../assets/img/den_rozhdeniya/bolshoy-zal-2etage-3.jpg'

// Летняя терасса - 1 этаж
import imgTerassaFirst1 from '../assets/img/den_rozhdeniya/terasa-1-1.jpg'
import imgTerassaFirst2 from '../assets/img/den_rozhdeniya/terasa-1-2.jpg'
import imgTerassaFirst3 from '../assets/img/den_rozhdeniya/terasa-1-3.jpg'

//
import vedushiy from '../assets/img/den_rozhdeniya/vedushiy.png'
import fotograf from '../assets/img/den_rozhdeniya/fotograf.png'

// Банкет
import imgBanket1 from '../assets/img/den_rozhdeniya/tanci-2.jpg'
import imgBanket2 from '../assets/img/den_rozhdeniya/showKatrin.jpg'
import imgBanket3 from '../assets/img/den_rozhdeniya/showRakasa.jpg'

import detskaya from '../assets/img/den_rozhdeniya/detskaya.jpg'
import poster from '../assets/img/den_rozhdeniya/poster.jpeg'


export const Birthday = () => {

    // Подарки
const imgsPresents = [
    { url: imgPresent1, alt: 'Именной торт от ресторана Хачапури Хинкальевич на праздновании дня рождения, выполненный с любовью и вниманием к деталям.' },
    { url: imgPresent2, alt: 'Вино как подарок от ресторана Хачапури Хинкальевич для именинника, подчеркнутое грузинским гостеприимством.' },
    { url: imgPresent3, alt: 'Разнообразие традиционных грузинских блюд на праздничном столе в ресторане Хачапури Хинкальевич, идеальное для дня рождения.' },
    { url: imgPresent4, alt: 'Грузинские народные танцы на праздновании дня рождения в ресторане Хачапури Хинкальевич в Алматы, добавляющие национальный колорит.' },
    { url: imgPresent5, alt: 'Семья наслаждается празднованием дня рождения в уютной атмосфере грузинского ресторана Хачапури Хинкальевич в Алматы.' }
  ];

  // Большой зал - 1 этаж
  const imgsBZal = [
    imgBZal1, imgBZal2,imgBZal3,imgBZal4,
  ]

  // Большой зал - 2 этаж
  const imgsBZalSecond = [
    imgBZalSecond1, imgBZalSecond2, imgBZalSecond3,
  ]

  // Летняя терасса - 1 этаж
  const imgsTerassaFirst = [
    imgTerassaFirst1, imgTerassaFirst2, imgTerassaFirst3,
  ]


  const [isOpened, setIsOpened] = useState(false)
	const defaultOptions = { verticalOffset: -50 }

	const handleScroll = event => {
		setIsOpened(false)
		animateScrollTo(document.querySelector(event.target.dataset.to), defaultOptions);
		document.body.classList.remove('scroll-off');
	}

  useEffect(() => {
		AOS.init()
	}, [])
  return (
    <div>

    <header className="page-header"
              data-aos="fade-in"
              data-aos-easing="ease-in-out"
              data-aos-duration="1000">
        <div className="page-header__wrapper">
            <div className="page-header__logos">
                <picture>
                    <source media="(min-width: 1280px)" srcSet
="https://hinkalnaya.kz/static/media/logo-desktop@2x.56d1c5b7a1404199e5f9.png" />
                    <source media="(min-width: 768px)" srcSet
="https://hinkalnaya.kz/static/media/logo-desktop@2x.56d1c5b7a1404199e5f9.png" />
                    <img className="page-header__logo" src
="https://hinkalnaya.kz/static/media/logo-desktop@2x.56d1c5b7a1404199e5f9.png" srcSet
="https://hinkalnaya.kz/static/media/logo-desktop@2x.56d1c5b7a1404199e5f9.png" alt="" />
                </picture>
                <img className="page-header__logo_second" src
="https://hinkalnaya.kz/static/media/second_logo.4e93a6f3488e9b3dba7b.png" srcSet
="https://hinkalnaya.kz/static/media/second_logo.4e93a6f3488e9b3dba7b.png" alt="" />
            </div>
<SiteNavigation />
        </div>
    </header>
    <main>
    <section className="promo pageBD"
						 data-aos="fade-in"
						 data-aos-easing="ease-in-out"
						 data-aos-duration="1000">
               <h1 className="visually-hidden">День рождение в ресторане грузинской кухни «Хачапури Хинкальевич»</h1>
			<div className="promo__video-background">
				<div className="promo__video-foreground">
					<Video className="promo__video"/>
				</div>
			</div>
			<div className="promo__wrapper">
				<b className="promo__lead"> Отметьте свой день рождения с нами!</b>
				<span className="promo__restaurant-name pd22">Бронируйте праздник сейчас в филиале по адресу Жетысу-4\32 и получите эксклюзивные бонусы!</span>
        <a href="https://wa.me/77759796984" target="_blank" className="menu__button wtsp topBtn"><FontAwesomeIcon icon={faWhatsapp} className='wtspBlack' /> Забронировать дату в WhatsApp</a>
			</div>
		</section>
    <section className="about-us__mrbt">
        <div className="about-us__wrapper about-us__wrapper--pageDR">
                <div className="about-us__first mob">
                    <h2 className="about-us__title">Почему стоит выбрать нас?</h2>
                    <h3 className="text">У нас для вас:</h3>
                    <ul className="text">
                        <li className="text">Всем именинникам в день рождения мы предоставляем 15% скидку и бенто тортик</li>
                        <br></br>
                        <li className="text">Сделайте свой день рождения незабываемым, отмечая его у нас! Просторный зал с праздничной атмосферой, изысканное меню и яркие шоу-программы сделают ваш вечер по-настоящему особенным.</li>
                        <li className="text">Пусть ваш праздник станет идеальным, а заботу обо всем остальном мы возьмем на себя. Бронируйте ваш лучший день рождения уже сегодня!</li>
                        <br></br>
                        <li className="text">Предложение доступно на филиалах:</li>
                        <li className="text">ул. Достык, 29</li>
                        <li className="text">ул. Жетысу-4, 32</li>
                    </ul>
                    <a href="https://wa.me/77759796984" target="_blank" className="menu__button wtsp-red why"><FontAwesomeIcon icon={faWhatsapp} className='wtspWhite' />Забронировать дату в WhatsApp</a>
                </div>
                <div className="slider-wrapper about-us__second"
					 data-aos="fade-up"
					 data-aos-offset="50px"
					 data-aos-easing="ease-in-out"
					 data-aos-duration="1000">
				<Slider className="about-us__slider"
								amount={imgsPresents.length}>{
                  imgsPresents.map(({url, alt }, index ) =>
						<SplideSlide className="about-us__slider-item about-us__slider-item--pageDR" key={index} >
							<img className="about-us__slider-image"
								src={url}
								alt={alt}
							/>
						</SplideSlide>
					)
				}</Slider>
			</div>
        </div>
        <div className='about-us__title__place--pageDR'>
            <h2 className="about-us__title about-us__title--pageDR">Найдите правильное место!</h2>
            <div className="about-us__text about-us__text--2 pd22">
                <p className="pd22">
                Наш филиал на Жетысу-4 абсолютно уникален, отличается от других точек большим пространством и не только!
                </p>
                <p className="pd22">
                У нас 2 уютных зала на первом и втором этаже, где удобно наблюдать за шоу-программой по выходным😉
                </p>
                <p className="pd22">
                А также летняя терраса в теплые деньки!
                </p>
                {/* <p className="pd22">
                    Приходя отдыхать к нам, ваши детки тоже найдут место для веселья!
                </p> */}
            </div>
        </div>
    </section>
    <section className="about-us">
        <div className="about-us__wrapper about-us__wrapper--pageDR">
                <div className="about-us__first">
                    <h2 className="about-us__title">ОСНОВНОЙ ЗАЛ</h2>
                    <h3 className="about-us__subtitle"><i>Вместимость: до 74 персон</i></h3>
                    <p className='textLeft'>На первом этаже вас встречает самый просторный зал для посиделок любого формата</p>
                    <p className='textLeft'>Мы постарались создать атмосферу декором в грузинском стиле в сочетании с современным дизайном.</p>
                    <p className='textLeft'>В этом зале и проходят наши легендарные вечера выходных дней</p>
                    <p className='textLeft'>Восточные, кавказские танцы и самые харизматичные ведущие, создают ваше настроение!</p>
                </div>
                <div className="slider-wrapper about-us__second"
					 data-aos="fade-up"
					 data-aos-offset="50px"
					 data-aos-easing="ease-in-out"
					 data-aos-duration="1000">
				<Slider className="about-us__slider"
								amount={imgsBZal.length}>{
                  imgsBZal.map((url, index) =>
						<SplideSlide className="about-us__slider-item about-us__slider-item--pageDR" key={index} >
							<img className="about-us__slider-image"
								src={url}
								alt=""
							/>
						</SplideSlide>
					)
				}</Slider>
			</div>
        </div>
    </section>
    <section className="about-us">
        <div className="about-us__wrapper about-us__wrapper--pageDR--rev">
                <div className="about-us__first">
                    <h2 className="about-us__title">ОСНОВНОЙ ЗАЛ - 2 ЭТАЖ</h2>
                    <h3 className="about-us__subtitle"><i>Вместимость: до 50 персон</i></h3>
                    <p>На втором этаже нашего ресторана вы сможете устроить любую камерную вечеринку, или же просто уединиться в своем мероприятии!</p>
                    <p>Но можно и одним глазком поглядывать на нашу сцену, наслаждаться шоу-программой с высока!</p>
                    <p>Сделайте свои выходные по-настоящему особенными! На втором этаже вас ждет просторный зал с идеальным видом на яркую шоу-программу. Вечер, наполненный яркими эмоциями, изысканными блюдами и атмосферой праздника, станет лучшим моментом недели. Не упустите возможность — приходите к нам за впечатлениями!</p>
                    {/* <p>Также на втором вас ожидает просторная детская игровая комната!</p> */}
                    {/* <p>Если вы пришли всей семьей, то второй этаж - место для вас. Пока вы будете ужинать, наслаждаясь вечером выходного дня, ваши детки смогут вдоволь наиграться и тоже весело провести время.</p> */}
                </div>
                <div className="slider-wrapper about-us__second"
					 data-aos="fade-up"
					 data-aos-offset="50px"
					 data-aos-easing="ease-in-out"
					 data-aos-duration="1000">
				<Slider className="about-us__slider"
								amount={imgsBZalSecond.length}>{
                                    imgsBZalSecond.map((url, index) =>
						<SplideSlide className="about-us__slider-item about-us__slider-item--pageDR" key={index} >
							<img className="about-us__slider-image"
								src={url}
								alt=""
							/>
						</SplideSlide>
					)
				}</Slider>
			</div>
        </div>
    </section>
    <section className='question'>
        <div className='question__wrapper'>
            <h2 className='question__title about-us__title'>Готовы к незабываемому празднику?</h2>
            <a href="https://wa.me/77759796984" target="_blank" className="menu__button wtsp-red qw"><FontAwesomeIcon icon={faWhatsapp} className='wtspWhite' />Забронировать дату в WhatsApp</a>
            <p className='question__subtitle'>Нажмите сюда, чтобы начать планирование вашего идеального вечера уже сегодня!</p>
        </div>
    </section>
    <section className="about-us">
        <div className="about-us__wrapper about-us__wrapper--pageDR">
                <div className="about-us__first">
                    <h2 className="about-us__title">ЛЕТНЯЯ ТЕРРАСА - 1 этаж</h2>
                    <h3 className="about-us__subtitle"><i>Вместимость: до 60 персон</i></h3>
                    <p className='textLeft'>Наша уютная летняя терраса - идеальное место, чтобы перекусить вдали от жары и на свежем воздухе!</p>
                    <p className='textLeft'>Отличное место, чтобы провести веселый корпоратив с коллегами, устроить небольшое закрытое мероприяте. Или же просто устроить романтический ужин при свечах со второй половинкой!</p>
                    <p className='textLeft'>Мы готовы воплотить любые ваши пожелания, и устроить мероприятие любого формата в лучших традициях грузинского гостеприимства!</p>
                </div>
                <div className="slider-wrapper about-us__second"
					 data-aos="fade-up"
					 data-aos-offset="50px"
					 data-aos-easing="ease-in-out"
					 data-aos-duration="1000">
				<Slider className="about-us__slider"
								amount={imgsTerassaFirst.length}>{
                                    imgsTerassaFirst.map((url, index) =>
						<SplideSlide className="about-us__slider-item about-us__slider-item--pageDR" key={index} >
							<img className="about-us__slider-image"
								src={url}
								alt=""
							/>
						</SplideSlide>
					)
				}</Slider>
			</div>
        </div>
    </section>
    <div className='about-us__title__place--pageDR'>
        <div className="about-us__title__place--pageDR">
            <p className="">
                Приходите к нам и наслаждайтесь уютной атмосферой, изысканными блюдами и теплотой гостеприимства!
            </p>
        </div>
    </div>
    {/* <section className="about-us"
        data-aos="fade-in"
        data-aos-offset="50px"
        data-aos-delay="100"
        data-aos-easing="ease-in-out"
        data-aos-duration="1000"
    >
        <div className="about-us__wrapper about-us__wrapper--pageDR--rev detskay">
                <div className="about-us__first">
                    <h2 className="about-us__title">Летняя терасса - 2 этаж.</h2>
                    <h3 className="about-us__subtitle"><i>Вместимость: до 40 персон.</i></h3>
                    <p>Летняя терраса на втором этаже — это уютное и спокойное место для проведения небольших мероприятий на свежем воздухе. С возможностью комфортно разместить до 40 гостей, эта терраса идеально подходит для приватных встреч, семейных ужинов или небольших праздничных мероприятий. Насладитесь видом на город, свежим воздухом и атмосферой настоящего грузинского гостеприимства в уютном уголке.</p>                </div>
                    <p>Также на втором вас ожидает просторная детская игровая комната!</p>
                    <p>Если вы пришли всей семьей, то второй этаж - место для вас. Пока вы будете ужинать, наслаждаясь вечером выходного дня, ваши детки смогут вдоволь наиграться и тоже весело провести время.</p>
                    <p>Любое детское мероприятие проведем под заказ, с аниматорами и шоу программой!</p>
                    <p>С 15:00 до 17:00. Ждем вас и ваших деток!</p>
                    <a href="https://wa.me/77759796984" target="_blank" className="menu__button wtsp-red why childBirthday"><FontAwesomeIcon icon={faWhatsapp} className='wtspWhite' />ОРГАНИЗОВАТЬ ДЕТСКИЙ ДЕНЬ РОЖДЕНИЯ</a>
                </div>
                <div className='wrapper_img_detskay'>
                    <img width={500} src={detskaya} alt="" />
				<Slider className="about-us__slider"
								amount={imgsTerassaFirst.length}>{
                                    imgsTerassaFirst.map((url, index) =>
						<SplideSlide className="about-us__slider-item about-us__slider-item--pageDR" key={index} >
							<img className="about-us__slider-image"
								src={url}
								alt=""
							/>
						</SplideSlide>
					)
				}</Slider>
			</div>
        </div>
    </section> */}
    <section className='hospitality'>
        <div className='hospitality__wrapper'>
            <div className='about-us__title__place--pageDR'>
                <h2 className="about-us__title about-us__title--pageDR">ОРГАНИЗУЕМ ВАШЕ МЕРОПРИЯТИЕ ЛЮБОГО ФОРМАТА!</h2>
                <p>Қыз ұзату, Тұсау кесер, Свадьбы, Дни рождения для взрослых и детей!</p>
                <p>И любые семейные мероприятия мы организуем в лучшем виде!</p>
                <p className="about-us__text--2 cntr">Мы позаботимся о мелочах. Мы предоставляем:</p>
            </div>
    <div className='hospitality__imgs'>
        <div className='hospitality__text'>
            <p>Дорогой гость, ресторана «Хачапури Хинкальевич», мы позаботимся о вашем празднике по всем традициям грузинского гостеприимства!</p>
            <p>Будет вкусно, весело, с танцами да песнями, тостами застольными!</p>
            <p>Бронируйте дату вашего торжества прямо СЕЙЧАС!</p>
            <p> И наслаждайтесь прекрасным вечером с друзьями и близкими! Ну а мы постараемся сделать его незабываемым</p>
        </div>
        {/* <div className='hospitality__imgs--container'>
                <div>
                    <video controls width="250" preload="metadata" poster={poster}>
                        <source src={video} type="video/webm" />
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
                <div>
                    <h3>Ведущего для вашего мероприятия</h3>
                    <p>Создайте незабываемую атмосферу на вашем празднике с профессиональным ведущим, который сделает ваш вечер ярким и незабываемым.</p>
                    <p className='addServices'>(По желанию гостя, услуга платная)</p>
                </div>
        </div> */}
        <div className='hospitality__imgs--container'>
                <div className='hospitality__img'>
                    <img src={fotograf} alt="fotograf" width={250} height={250}/>
                </div>
                <div>
                    <h3>Фотографа на час</h3>
                    <p>Запечатлейте самые важные моменты вашего мероприятия с помощью профессионального фотографа. Лучшие кадры станут памятными снимками на долгие годы.</p>
                    <p className='addServices'>(По желанию гостя, услуга платная)</p>
                </div>
        </div>
    </div>
    </div>
    </section>
<section className='banket'>
    <div className="banket__wrapper">
        <div className='about-us__title__place--pageDR'>
            <h2 className="about-us__title about-us__title--pageDR banket__title">И, конечно, какой же банкет без танцев, еды и вина?</h2>
            <p className="about-us__text about-us__text--2 banket__subtitle">В ресторане Хачапури Хинкальевич мы знаем, что настоящий праздник начинается с движения и вкусовых удовольствий. Грузинские танцы, полные энергии и страсти, погружают вас в атмосферу древних традиций и создают незабываемые моменты радости. А что может быть лучше, чем насладиться этим, попивая бокал изысканного вина и пробуя настоящие грузинские блюда, приготовленные по старинным рецептам?</p>
        </div>
        <div className="banket__imgs imgs">
            <div className='www imgs_1'>
                <img src={imgBanket1} alt="imgBanket" width={350} height={350}/>
                <p>-Ансамбль БАХАР</p>
            </div>
            <div className='www'>
                <img src={imgBanket2} alt="imgBanket" width={350} height={350}/>
                <p>-Восточное шоу от Катрин</p>
            </div>
            <div className='www'>
                <img src={imgBanket3} alt="imgBanket" width={350} height={350}/>
                <p>-Восточное шоу RAKASA</p>
            </div>
        </div>
        <p className='imgs-text'>Про наши танцевальные вечера можно сказать только одно - лучше один раз увидеть, чем сто раз услышать!</p>
        <p className='imgs-text'>График музыкантов: Четверг, пятница, суббота – Достык и Жетысу</p>
        <p className='imgs-text'>График танцоров: Пятница, суббота - Достык и Жетысу</p>
    </div>
</section>
<section className="bron">
    <div className="bron__wrapper">
    <div className='about-us__title__place--pageDR'>
        <h2 className="about-us__title about-us__title--pageDR banket__title">Скорей бронируйте столик на ваш незабываемый вечер!</h2>
    </div>
    <div className='bron__description'>
        <h3 >Всего несколько шагов до вашего идеального вечера:</h3>
        <ul>
            <li>1. Напишите нам в WhatsApp по кнопке ниже.</li>
            <li>2. Укажите дату и количество гостей.</li>
            <li>3. Подтвердите бронирование и готовьтесь к празднику!</li>
        </ul>
        <p>Мы гарантируем личный подход и внимание к каждой детали вашего мероприятия.</p>
        </div>
    </div>
</section>
<section className='question'>
        <div className='question__wrapper'>
            <h2 className='question__title about-us__title'>Готовы к незабываемому празднику?</h2>
            <a href="https://wa.me/77759796984" target="_blank" className="menu__button wtsp-red qw"><FontAwesomeIcon icon={faWhatsapp} className='wtspWhite' />Забронировать дату в WhatsApp</a>
            <p className='question__subtitle'>Нажмите сюда, чтобы начать планирование вашего идеального вечера уже сегодня!</p>
        </div>
    </section>
    <Contacts/>
    <Footer />
    </main>
    </div>
  )
}
