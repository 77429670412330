import { useState } from 'react';
import { isCurrentTimeInRange } from '../polyfills';


const Modal = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [className, setClassName] = useState('');

  const beforeLunch = isCurrentTimeInRange('07:00', '14:00');

  const bannerImgClass = beforeLunch ? 'BeforeLunch' : 'AfterLunch';

  /*const linkBanner = beforeLunch
    ? 'https://api.whatsapp.com/send/?phone=77076545424&text=%D0%A5%D0%BE%D1%87%D1%83%20%D1%81%D0%BA%D0%B8%D0%B4%D0%BA%D1%83%2020%20%%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D0%B5%D0%B4%20%D0%B2%20%D1%80%D0%B5%D1%81%D1%82%D0%BE%D1%80%D0%B0%D0%BD%20%D0%BD%D0%B0%20%D1%83%D0%BB.%20%D0%96%D0%B5%D1%82%D1%8B%D1%81%D1%83,%204'
    : 'https://api.whatsapp.com/send/?phone=77076545424&text=Хочу+15%25+скидку+на+первое+посещение+новой+точки%21+Увидел+ваш+баннер+на+сайте&type=phone_number&app_absent=0';*/


  const linkBanner = 'https://api.whatsapp.com/send/?phone=77012089808&text=Хочу хинкали за 990 тенге! Увидел ваш баннер на сайте&type=phone_number&app_absent=0'
  const onClose = () => {
    setClassName('hide_animation');
    setTimeout(() => {
      setIsOpen(false);
    }, 500);
  };

  return (
    isOpen && (
      <div className={`Modal ${className} ${bannerImgClass}`}>
        <div className="Modal_Container">
          <a href={linkBanner} target="_blank">
            <div className={`Modal_BannerImg${bannerImgClass}`}></div>
          </a>
          <div className="Modal_Closer" onClick={onClose}></div>
        </div>
      </div>
    )
  );
};

export default Modal;