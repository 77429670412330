import React from 'react'
import Header from '../components/Header'
import Promo from '../components/Promo'
import About from '../components/About'
import NewMenuItems from '../components/NewMenuItems'
import Menu from '../components/Menu'
import Delivery from '../components/Delivery'
import Gallery from '../components/Gallery'
import Contacts from '../components/Contacts'
import Footer from '../components/Footer'
import Modal from "../components/Modal";

export const Home = () => {
  return (
    <div>
		 <Modal />
		<Header/>
		<Promo />
		<About/>
		<NewMenuItems/>
		<Menu/>
		<Delivery/>
		<Gallery/>
		<Contacts/>
		<Footer/>
    </div>
  )
}
