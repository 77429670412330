import React, { useEffect, useState } from "react"
import SiteNavigation from "./SiteNavigation";
import AOS from 'aos';
import modalImg from '../assets/img/NY_new.jpg'
import closeButton from '../assets/img/close-button.png'

import 'aos/dist/aos.css';

const Header = () => {

	const [modal, setModal] = useState(false)

	const closeModal = () => {
		setModal(false)
	}

	useEffect(() => {
		AOS.init()
	})

	useEffect(() => {
		const delay = setTimeout(() => {
			setModal(true);
		}, 2000);

		return () => clearTimeout(delay);
	}, []);

	return (
		<header className="page-header"
			data-aos="fade-in"
			data-aos-easing="ease-in-out"
			data-aos-duration="1000">
			<div className="page-header__wrapper">
				{/* <div className={`popap_discount_wrapper ${modal ? 'open' : 'close'}`}>
					<div className="overlay" onClick={closeModal}></div>
					<div className="popap_discount_content">
						<a href="https://wa.me/77076545424" target="_blank" rel="noopener noreferrer">
							<img className="popap_img" src={modalImg} alt="discount" />
						</a>
						<img className="close_popap_btn" src={closeButton} alt="X" onClick={closeModal} />
					</div>
				</div> */}
				<div className="page-header__logos">
					<picture>
						<source media="(min-width: 1280px)"
							srcSet={require('../assets/img/logo-desktop@2x.png')} />
						<source media="(min-width: 768px)"
							srcSet={require('../assets/img/logo-desktop@2x.png')} />
						<img className="page-header__logo"
							src={require('../assets/img/logo-desktop@2x.png')}
							srcSet={require('../assets/img/logo-desktop@2x.png')}
							alt="" />
					</picture>
					<img className="page-header__logo_second"
						src={require('../assets/img/second_logo.png')}
						srcSet={require('../assets/img/second_logo.png')}
						alt=""
					/>
				</div>
				<SiteNavigation />
			</div>
		</header>
	);
}

export default Header;